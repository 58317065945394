<template>
    <div class="card h-100" v-if="loadPage">
        <div class="card_body h-100">
            <Loader/>
        </div>
    </div>
    <Form v-else :validation-schema="schema">
        <div class="card">
            <div class="card__header card--border o-flex o-flex--center o-flex--justify">
                <div class="upload-image-wrapper">
                    <input ref="photo" @change="setPhoto" type="file" id="upload-photo">
                    <label class="upload-image" for="upload-photo" :style="'background-image: url(' + photo + ')'">
                        <svg width="30"  v-if="!photo" viewBox="0 0 448 512">
                            <path fill="#414E62" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"/>
                        </svg>
                    </label>
                    <p class="mb0">{{firstName}} {{lastName}}</p>
                </div>
                <ToggleSwitch :id="'1'" :status="switchStatus" @status="handleStatusChange" class="toggle-switch"/>
            </div>
            <div class="card__body">
                <div class="row">
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>First name <span>*</span></p>
                            <div class="relative">
                                <Field type="text" name="firstName" class="input input--primary" :placeholder="'Enter'" v-model="firstName"/>
                                <ErrorMessage class="text-error" name="firstName"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Last name <span>*</span></p>
                            <div class="relative">
                                <Field type="text" name="lastName" class="input input--primary" :placeholder="'Enter'" v-model="lastName"/>
                                <ErrorMessage class="text-error" name="lastName"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Start Date <span>*</span></p>
                            <div class="relative">
                                <Field name="startDate" v-slot="{ field }">
                                    <vue-date-picker
                                        v-bind="field"
                                        :model-type="'yyyy-MM-dd'"
                                        :format="'dd.MM.yyyy'"
                                        placeholder="Select Date"
                                        v-model="startDate"
                                        :ui="{ input: 'date-picker' }"
                                    />
                                </Field>
                                <ErrorMessage class="text-error" name="startDate"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>End Date</p>
                            <div class="relative">
                                <vue-date-picker
                                required
                                :model-type="'yyyy-MM-dd'"
                                :format="'dd.MM.yyyy'"
                                placeholder="Select Date"
                                v-model="endDate"
                                name="endDate"
                                :ui="{ input: 'date-picker' }"
                                />
                                <ErrorMessage class="text-error" name="endDate"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Email <span>*</span></p>
                            <div class="relative">
                                <Field type="email" name="email" class="input input--primary" :placeholder="'Enter'" v-model="email"/>
                                <ErrorMessage class="text-error" name="email"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Phone <span>*</span></p>
                            <div class="relative">
                                <Field type="phone" name="phone" class="input input--primary" :placeholder="'Enter'" v-model="phone"/>
                                <ErrorMessage class="text-error" name="phone"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Contract Type <span>*</span></p>
                            <div class="relative">
                                <Field name="contract" v-slot="{ field }">
                                    <VueMultiselect
                                        v-bind="field"
                                        v-model="contractSelected"
                                        :options="contractType"
                                        label="name"
                                        track-by="id"
                                        placeholder="Select one"
                                    />
                                </Field>
                                <ErrorMessage name="contract" class="text-error" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="divider divider-1 mb"></div>

                <h2><strong>Employee Competencies</strong></h2>
                <div class="row">
                    <div class="col-xs-12 col-md-6 col-lg-6">
                        <div class="input-box">
                            <p>Positions</p>
                            <div class="relative">
                                <VueMultiselect
                                v-model="positions"
                                placeholder="Select one"
                                :options="allPositions"
                                :taggable="true"
                                :multiple="true"
                                label="name"
                                :close-on-select="false"
                                class="input--tags"
                                track-by="name"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Seniority</p>
                            <div class="relative">
                                <VueMultiselect name="seniority" v-model="senioritySelected" placeholder="Select one" :options="allSeniorities" label="name" track-by="name"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-12 col-lg-12">
                        <div class="input-box">
                            <p>Skills</p>
                            <VueMultiselect
                                v-model="skills"
                                placeholder="Select one"
                                :options="allSkills"
                                :taggable="true"
                                :multiple="true"
                                label="name"
                                :close-on-select="false"
                                class="input--tags"
                                track-by="name"/>
                        </div>
                    </div>
                </div>
                <div class="divider divider-1 mb"></div>
                <h2><strong>Additional Info</strong></h2>
                <div class="row">
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Team lead</p>
                            <div class="relative">
                                <VueMultiselect name="teamLead" v-model="teamLeadSelected" placeholder="Select one" :options="allTeamLeads" label="first_name" track-by="first_name"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Location</p>
                            <div class="relative">
                                <VueMultiselect name="location" v-model="locationSelected" placeholder="Select one" :options="allLocations" label="name" track-by="name"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card__footer card--border o-flex o-flex--right">
                <router-link :to="{name: 'employee'}" class="button button--secondary">Cancel</router-link>
                <button type="button" @click="submit" class="button button--primary" :disabled="working">Save Changes</button>
            </div>
        </div>
    </Form>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Loader from '../../components/Loader/Loader.vue';
import VueMultiselect from 'vue-multiselect';
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitch.vue';
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { getPositions, getSkills, getSeniority, getTeamleads, getLocations, getContractTypes } from '../../service/general';

export default {
    name:"EmployForm",
    components: {
        Field,
        Form,
        ErrorMessage,
        VueDatePicker,
        VueMultiselect,
        ToggleSwitch,
        Loader,
    },
    emits: ['loading', 'formData'],
    props: {
        working: {
            type: Boolean,
            default: false,
        },
        payload: {
            type: Object,
            default: {},
        },
    },

    data() {
        return {
            loadPage: true,
            photo: null,
            switchStatus: "active",
            firstName: "",
            lastName: "",
            startDate: null,
            endDate: null,
            email: "",
            phone: "",
            contractSelected: null,
            contractType: [],
            positions: [],
            allPositions: [],
            skills: [],
            allSkills: [],
            senioritySelected: null,
            allSeniorities: [],
            teamLeadSelected: null,
            allTeamLeads: [],
            locationSelected: null,
            allLocations: [],
            schema: yup.object({
                firstName: yup
                .string()
                .required('First name is required')
                .min(2, 'Must be at least 2 characters'),
                lastName: yup
                .string()
                .required('Last name is required')
                .min(2, 'Must be at least 2 characters'),
                startDate: yup
                .date()
                .nullable()
                .required('Date is required'),
                email: yup
                .string()
                .required('Email is required')
                .email('Must be a valid email'),
                phone: yup
                .string()
                .required('Phone is required')
                .matches(/^[0-9]{10,15}$/, 'Phone number must be between 10 and 15 digits'),
                contractId: yup
                .object()
                .nullable()
                .shape({
                    id: yup.number().required('Contract type is required'),
                })
                .required('Contract type is required'),
            }),
        }
    },
    methods: {
        handleStatusChange(newStatus) {
            this.switchStatus = newStatus;
        },

        updateData(payload) {

            if (payload) {
                if (payload.file !== undefined) this.photo = payload.file;
                if (payload.first_name !== undefined) this.firstName = payload.first_name;
                if (payload.last_name !== undefined) this.lastName = payload.last_name;
                if (payload.email !== undefined) this.email = payload.email;
                if (payload.phone !== undefined) this.phone = payload.phone;
                if (payload.start_date !== undefined) this.startDate = payload.start_date;
                if (payload.last_working_date !== undefined) this.endDate = payload.last_working_date;
                if (payload.status !== undefined) this.switchStatus = payload.status;
                if (payload.contract_type !== undefined) this.contractSelected = payload.contract_type;
                if (payload.seniority_level !== undefined) this.senioritySelected = payload.seniority_level;
                if (payload.team_lead !== undefined) this.teamLeadSelected = payload.team_lead;
                if (payload.location !== undefined) this.locationSelected = payload.location;

                // Update arrays only when they have valid data
                if (Array.isArray(payload.skills) && payload.skills.length) {
                this.skills = payload.skills;
                }
                if (Array.isArray(payload.positions) && payload.positions.length) {
                this.positions = payload.positions;
                }
            }


            this.loadPage = false;
            this.$emit('loading', false);
        },

        async setPhoto($event) {

            if (!$event.target.files[0]) {
                return;
            }

            this.photo = $event.target.files[0];

            // const [file] = $event.target.files;
            // this.type = file.type;

            // const reader = new FileReader();
            // console.log(reader)
            // reader.readAsDataURL(file);

            // await new Promise((resolve) => {
            //     reader.onloadend = () => {
            //     this.photo = reader.result;
            //     resolve();
            //     };
            // });
        },
        async submit() {
            const skills = this.skills.map((item) => {
                return item.id
            })

            const positions = this.positions.map((item) => {
                return item.id
            })
            const payload = {
                file: this.photo,
                first_name: this.firstName,
                last_name: this.lastName,
                skill_ids: skills,
                status: this.switchStatus,
                position_ids: positions,
                email: this.email,
                phone: this.phone,
                start_date: this.startDate,
                last_working_date: this.endDate,
                seniority_level_id: this.senioritySelected?.id,
                location_id: this.locationSelected?.id,
                contract_type_id: this.contractSelected?.id,
                team_lead_id: this.teamLeadSelected?.id,
            }
            this.$emit('formData', payload);
        }
    },
    async created() {
        const [contractType, allPositions, allSkills, allSeniorities, allTeamLeads, allLocations]  = await Promise.all([getContractTypes(), getPositions(), getSkills(), getSeniority(), getTeamleads(), getLocations()])

        this.contractType = contractType;
        this.allPositions = allPositions;
        this.allSkills = allSkills;
        this.allSeniorities = allSeniorities;
        this.allTeamLeads = allTeamLeads;
        this.allLocations = allLocations;
        this.updateData(this.payload);
    },
}
</script>
<style lang="scss" scoped>
    .card__header {
        padding-bottom: 24px;
        padding-top: 9px;
    }

    .card__body {
        padding: 24px 0;
    }

    .card__footer {
        padding-top: 24px;
        padding-bottom: 9px;
        gap: 10px;
    }

    .input-box {
        margin-bottom: 24px;
    }

    .upload-image-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
            font-size: 18px;
        }

        input {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            z-index: -1;
        }
    }

    .upload-image {
        width: 80px;
        aspect-ratio: 1 / 1;
        cursor: pointer;
        background-size: cover;
        background-position: 50% 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #e4e7ec;
    }
</style>