<template>
    <main :class="{'h-100': loadPage}">
      <section class="project-filter">
        <div class="search-box">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                d="M17 17L21 21M11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 13.2053 18.1077 15.2022 16.6644 16.6493C15.2161 18.1015 13.213 19 11 19Z"
                stroke="#637083"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                />
            </svg>
            <input
                type="search"
                class="input input--primary input--search"
                placeholder="Search projects"
            />
        </div>
    
        <div class="filter-wrapper">
            <VueMultiselect v-model="skillId" placeholder="Select one" :options="skills" label="name" track-by="name"/>
            <VueMultiselect v-model="seniorityId" placeholder="Select one" :options="skills" label="name" track-by="name"/>
            <VueMultiselect v-model="roleId" placeholder="Select one" :options="skills" label="name" track-by="name"/>
        </div>
      </section>
      <div class="card">
        <div v-if="!loadPage" class="card__header o-flex o-flex--center o-flex--justify">
          <div class="flag">
            <div class="flag__icon">
              <img src="@/assets/img/project.svg" alt="list" />
            </div>
            <div class="flag__content">
              <h2 class="u-font-500 mb0">Projects</h2>
              <p class="mb0">Manage your projects here</p>
            </div>
          </div>
          <router-link :to="{name: 'add-project'}"
            class="button button--primary"
            >Add new</router-link
          >
        </div>
        <div class="card__body" :class="{'h-100': loadPage}">
          <Loader v-if="loadPage"/>
          <div v-else class="table-responsive" >
            <table class="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Manager</th>
                    <th>Priority</th>
                    <th>Status</th>
                    <th width="10%" class="u-text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="project in data" :key="project.id">
                    <td>
                      <div class="o-flex o-flex--center">
                        <span class="color-box-mark mr-" :style="`background-color: ${project.color}`"></span>
                        {{ project.name }}
                      </div>
                    </td>
                    <td>{{project.project_manager.first_name}} {{project.project_manager.last_name}}</td>
                    <td>
                      <PriorityBox :priority="project.priority"/>
                    </td>
                    <td>
                      <StatusBox :status="project.status"/>
                    </td>
                    <td class="u-text-center">
                      <a href="javascript:;"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.9693 4.70964L13.2026 3.47631C13.8535 2.82544 14.9088 2.82544 15.5597 3.47631L16.7382 4.65483C17.3891 5.3057 17.3891 6.36097 16.7382 7.01185L15.5049 8.24517M11.9693 4.70964L3.95627 12.7227C3.67954 12.9994 3.50902 13.3647 3.47458 13.7545L3.27284 16.0382C3.2272 16.5549 3.6596 16.9873 4.17627 16.9417L6.45996 16.7399C6.84979 16.7055 7.21508 16.535 7.49181 16.2582L15.5049 8.24517M11.9693 4.70964L15.5049 8.24517" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg></a>
                    </td>
                  </tr>
                </tbody>
            </table>
          </div>
        </div>
    
        <div class="card__footer" v-if="!loadPage">
            <Pagination @page-click="getProjects" :info="pagination" />
        </div>
      </div>
    </main>
  </template>
  
  <script>
  import VueMultiselect from 'vue-multiselect'
  import Pagination from "../../components/Pagination/Pagination.vue";
  import { getPaginationPayload } from "../../service/general";
  import { getProjectsApi } from '../../api/projects/index';
  import { toast } from 'vue3-toastify';
  import StatusBox from '../../components/StatusBox/StatusBox.vue';
  import PriorityBox from '../../components/PriorityBox/PriorityBox.vue';
  import Loader from '../../components/Loader/Loader.vue';

  
  export default {
    name: 'ProjectPage',
  
    components: {
      VueMultiselect,
      Pagination,
      StatusBox,
      PriorityBox,
      Loader,
    },
  
    data() {
      return {
        loadPage: true,
        pagination: null,
        skillId: null,
        seniorityId: null,
        roleId: null,
        skills: [
            {
                "id": 13,
                "parent_id": 11,
                "name": "Acccount Management",
                "status": "active",
                "is_pm": 1,
                "created_at": "2024-09-18T11:26:15.000000Z",
                "updated_at": "2024-09-18T11:26:15.000000Z"
            },
            {
                "id": 4,
                "parent_id": 1,
                "name": "Angular",
                "status": "active",
                "is_pm": 0,
                "created_at": "2024-09-18T11:26:15.000000Z",
                "updated_at": "2024-09-18T11:26:15.000000Z"
            },
            {
                "id": 5,
                "parent_id": null,
                "name": "Backendd",
                "status": "active",
                "is_pm": 0,
                "created_at": "2024-09-18T11:26:15.000000Z",
                "updated_at": "2024-09-18T11:43:06.000000Z"
            },
            {
                "id": 10,
                "parent_id": 5,
                "name": "C#d",
                "status": "active",
                "is_pm": 0,
                "created_at": "2024-09-18T11:26:15.000000Z",
                "updated_at": "2024-09-18T11:43:11.000000Z"
            },
        ],
        data:[]
      };
    },
    methods: {
      async getProjects(page = 1) {
          try {
              const { data } = await getProjectsApi({page:page});
              this.data = data.data
              this.pagination = getPaginationPayload(data)
              this.loadPage = false;
          } catch (e) {
              toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
          }
  
          // await getResources({page:page}).then((data) => {
          //     if(!data) return
          //     console.log(data)
          //     this.data = data.data.data;
          //     this.pagination = getPaginationPayload(this.data)
          //     this.loadPage = false;
  
          // }).catch((error) => {
          //     console.log('odje')
          //     console.log(error)
          //     console.log('odje2')
          //     toast.error(error.response?.data.message);
              
  
          //     this.loadPage = false;
          // });
  
          
      },
    },
    mounted() {
      this.getProjects();
    }
  };
  </script>
  
  <style lang="scss" scoped>
    main {
      display: flex;
      flex-direction: column;

      .card {
          flex: 1;
      }
    }
    .project-filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
    }
  
    .color-box-mark {
      width: 22px;
      height: 22px;
      border-radius: 6px;
      background-color: red;
    }
  </style>
  