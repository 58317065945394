<template>
    <main>
        <Header></Header>
        <ProjectForm/>
    </main>
</template>
<script>
import Header from '@/components/Header/Header.vue';
import ProjectForm from '../project/ProjectForm.vue'
export default {
    name: "AddProject",
    components: {
        Header,
        ProjectForm,
    }, 
}
</script>
<style lang="scss" scoped>
main {
    height: auto;
}
</style>