import axios from "axios";

export const getResourcesApi = (query = null) => {
  return axios.get(`/api/admin/resources${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const getEmployeeDetailsApi = (id) => {
  return axios.get(`/api/admin/resources/${id}`)
}

export const addEmployeeApi = (payload) => {
  const formData = new FormData();

  Object.keys(payload).forEach((key, index) => {
    if (payload[key] === null || payload[key] === undefined) {
      // skip
    } else if(Array.isArray(payload[key])) {
      for (let i = 0; i < payload[key].length; i++) {
        formData.append(`${key}[${i}]`, payload[key][i]);
      }
    } else {
      formData.append(key, payload[key]);
    };
  });

  return axios.post(`/api/admin/resources`, formData, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
  });
}

export const updateEmployeeApi = (id, payload) => {
  return axios.post(`/api/admin/resources/${id}`, payload)
}

export const deleteEmployeeApi = (id) => {
  return axios.delete(`/api/admin/resources/${id}`)
}


