export default {
    namespaced: true,
    state() {
        return {
            user: []
        }
    },

    mutations: {
        setUser(state, value) {
            state.user = value;
        }
    },
    actions: {
        setUser(context, value) {
            context.commit('setUser', [
                ...context.state.user, value
            ])
        },
    }
}