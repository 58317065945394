<template>
  <div
    class="aside__article"
    :class="bigAccordion === 'big-accordion' ? 'big-accordion' : ''"
  >
    <div
      class="aside-title"
      :class="accordion ? 'is-active' : ''"
      @click="accordion = !accordion"
    >
      <slot name="asideTitle"></slot>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 7.5L10 12.5L15 7.5"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="aside-options" :class="accordion ? 'is-active' : ''">
      <div class="aside-option__wrapper">
        <slot name="asideOptions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    bigAccordion: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      accordion: false,
    };
  },
};
</script>

<style scoped lang="scss">
.aside__article {
  border-bottom: 1px solid #fafafa;
}

.aside-title {
  /* padding: 17px 0; */
  font-size: 14px;
  line-height: 1.3;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--aside-link-color);

  svg {
    transition: all 0.3s ease;
  }

  &.is-active {
    svg {
      transform: rotate(180deg);
    }
  }
}

.aside-options {
  height: auto;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
  max-height: 0;

  &.is-active {
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    max-height: 99999px;
  }
}
</style>
