import axios from "axios";

export const getProjectsApi = (query = null) => {
    return axios.get(`/api/admin/projects${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const addProjectsApi = (addProjects) => {
    return axios.post(`/api/admin/projects`, {name: addProjects})
}

export const updateProjectsApi = (id, name) => {
    return axios.post(`/api/admin/projects/`+ id, {name})
}

export const deleteProjectsApi = (id) => {
    return axios.delete(`/api/admin/projects/` + id )
}

