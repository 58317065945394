import './assets/main.css'
import "@/assets/scss/app.scss";
import 'vue3-toastify/dist/index.css';
import axios from "axios";
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { clientLogout } from './service/Auth';

axios.defaults.baseURL = import.meta.env.VITE_APP_API_URL;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {

  // console.log("error1",error)
    return Promise.reject(error);
});
  

axios.interceptors.response.use(
    response => response, 
    async (error) => {
      if (error.response?.status === 401) {
        await clientLogout();
        return router.push({ name: 'login' });
      }
      return Promise.reject(error);
    }
);
createApp(App).use(store).use(router).mount('#app')
