<template>
  <main :class="{'h-100': loadPage}">
    <teleport to="#modals">
      <transition name="fade">
        <Modal
          v-if="openModal"
          class="modal"
          :closeButton="false"
          @close="openModal = false"
        >
          <template #header>
            <div class="flag">
              <div class="flag__icon">
                <img
                  src="@/assets/img/add-contract-type.svg"
                  alt="add-contract-type"
                />
              </div>
              <div class="flag__content">
                <h2 class="u-font-500 mb0">Add new Teams</h2>
                <p class="mb0">Description text goes here</p>
              </div>
            </div>
          </template>
          <template #body>
            <Form :validation-schema="schema">
              <div class="input-box">
                  <p>Teams name</p>
                  <div class="relative">
                      <Field type="text" name="addTeams" class="input input--primary" :placeholder="'Enter'" v-model="teamsValue"/>
                      <ErrorMessage class="text-error" name="addTeams"  />
                  </div>
              </div>
            </Form>
          </template>
          <template #footer>
            <div class="o-flex modal-button-wrapper">
              <a
                href="#"
                class="button button--secondary"
                @click="openModal = false"
                >Cancel</a
              >
              <button type="button" :disabled="workingAdd || teamsValue === ''" @click="addTeams" class="button button--primary">Save</button>
            </div>
          </template>
        </Modal>
      </transition>
    </teleport>
    <teleport to="#modals">
      <transition name="fade">
        <Modal
          v-if="openModalEdit"
          class="modal"
          :closeButton="false"
          @close="openModalEdit = false"
        >
          <template #header>
            <div class="flag">
              <div class="flag__icon">
                <img
                  src="@/assets/img/add-contract-type.svg"
                  alt="add-contract-type"
                />
              </div>
              <div class="flag__content">
                <h2 class="u-font-500 mb0">Edit Teams</h2>
                <p class="mb0">Description text goes here</p>
              </div>
            </div>
          </template>
          <template #body>
            <Form :validation-schema="schema">
              <div class="input-box">
                  <p>Teams name</p>
                  <div class="relative">
                      <Field type="text" name="addTeams" class="input input--primary" :placeholder="'Enter'" v-model="teamsValue"/>
                      <ErrorMessage class="text-error" name="addTeams"  />
                  </div>
              </div>
              <ToggleSwitch :id="'1'" :status="switchStatus" @status="handleStatusChange" class="toggle-switch"/>
            </Form>
          </template>
          <template #footer>
            <div class="o-flex modal-button-wrapper">
              <a
                href="#"
                class="button button--secondary"
                @click="openModalEdit = false"
                >Cancel</a
              >
              <button type="button" :disabled="workingEdit || teamsValue === ''" @click="updateTeams" class="button button--primary">Save</button>
            </div>
          </template>
        </Modal>
      </transition>
    </teleport>
    <teleport to="#modals">
      <transition name="fade">
        <Modal
          v-if="openModalDelete"
          class="modal"
          :closeButton="false"
          @close="openModalDelete = false"
        >
          <template #header>
            <div class="flag">
              <div class="flag__icon">
                <img
                  src="@/assets/img/add-contract-type.svg"
                  alt="add-contract-type"
                />
              </div>
              <div class="flag__content">
                <h2 class="u-font-500 mb0">Delete Teams</h2>
              </div>
            </div>
          </template>
          <template #body>
            <p class="mb0">Are you sure you want to delete <strong>- {{teamsValue}} -</strong></p>
          </template>
          <template #footer>
            <div class="o-flex modal-button-wrapper">
              <a
                href="#"
                class="button button--secondary"
                @click="openModalDelete = false"
                >Cancel</a
              >
              <button type="button" :disabled="workingDelete" @click="deleteTeams" class="button button--primary">Delete</button>
            </div>
          </template>
        </Modal>
      </transition>
    </teleport>
    <div class="card">
      <div v-if="!loadPage" class="card__header o-flex o-flex--center o-flex--justify">
        <div class="flag">
          <div class="flag__icon">
            <img src="@/assets/img/teams.svg" alt="teams" />
          </div>
          <div class="flag__content">
            <h2 class="u-font-500 mb0">Teams</h2>
            <p class="mb0">Manage your teams here</p>
          </div>
        </div>
        <a
          href="javascript:;"
          @click="openModal = true"
          class="button button--primary"
          >Add new</a
        >
      </div>
      <div class="card__body" :class="{'h-100': loadPage}">
        <Loader v-if="loadPage"/>
        <div v-else class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th width="30%">Name</th>
                <th>Date Added</th>
                <th>Status</th>
                <th width="10%" class="u-text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data" :key="item.id">
                <td>{{item.name}}</td>
                <td>{{formatDate(item.created_at)}}</td>
                <td>
                  <StatusBox :status="item.status"/>
                </td>
                <td>
                  <ul
                    class="o-flex o-flex--center o-flex--justify-center action-list"
                  >
                    <li>
                      <a href="javascript:;" @click="editModalHandler(item.id, item.name, item.status)">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.9693 4.70952L13.2026 3.47619C13.8535 2.82532 14.9088 2.82532 15.5597 3.47619L16.7382 4.6547C17.3891 5.30558 17.3891 6.36085 16.7382 7.01173L15.5049 8.24505M11.9693 4.70952L3.95627 12.7226C3.67954 12.9993 3.50902 13.3646 3.47458 13.7544L3.27284 16.0381C3.2272 16.5548 3.6596 16.9872 4.17627 16.9415L6.45996 16.7398C6.84979 16.7054 7.21508 16.5348 7.49181 16.2581L15.5049 8.24505M11.9693 4.70952L15.5049 8.24505"
                            stroke="#637083"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;" @click="deleteModalHandler(item.id, item.name)">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.6667 7.50008L15.0042 16.9553C14.8641 17.7522 14.1718 18.3334 13.3627 18.3334H6.63728C5.82816 18.3334 5.13592 17.7522 4.9958 16.9553L3.33333 7.50008M17.5 5.00008H12.8125M12.8125 5.00008V3.33341C12.8125 2.41294 12.0663 1.66675 11.1458 1.66675H8.85417C7.93369 1.66675 7.1875 2.41294 7.1875 3.33341V5.00008M12.8125 5.00008H7.1875M2.5 5.00008H7.1875"
                            stroke="#637083"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card__footer">
          <Pagination @page-click="getTeams" :info="pagination" />
      </div>
    </div>
  </main>
</template>
<script>
import Modal from '../components/Modal/Modal.vue';
import StatusBox from '../components/StatusBox/StatusBox.vue';
import ToggleSwitch from '../components/ToggleSwitch/ToggleSwitch.vue';
import {teamsApi, addTeamsApi, updateTeamsApi, deleteTeamsApi} from '../api/teams/index';
import moment from "moment";
import Pagination from "../components/Pagination/Pagination.vue";
import { getPaginationPayload } from "../service/general";
import { toast } from 'vue3-toastify';
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import Loader from '../components/Loader/Loader.vue';
export default {
  name: 'Teams',
  components: {
    Modal,
    Pagination,
    Field,
    Form,
    ErrorMessage,
    StatusBox,
    ToggleSwitch,
    Loader,
  },

  data() {
    return {
      loadPage: true,
      workingAdd: false,
      workingEdit: false,
      workingDelete: false,
      openModal: false,
      openModalEdit: false,
      openModalDelete: false,
      data:[],
      pagination: null,
      teamsValue: "",
      teamsId: null,
      switchStatus: null,
      schema: yup.object({
        addTeams: yup
          .string()
          .required('Teams value is required')
          .min(2, 'Must be at least 2 characters')
      }),
    };
  },

  methods: {
    handleStatusChange(newStatus) {
      this.switchStatus = newStatus;
    },
    
    async getTeams(page = 1) {
      
      try {
          const { data } = await teamsApi({page:page});
          this.data = data.data
          this.loadPage = false;
          this.pagination = getPaginationPayload(data)
      } catch (e) {
          toast.error(e);
      }
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },

    async addTeams() {
      this.workingAdd = true;
      try {
        await addTeamsApi(this.teamsValue);
        toast.success(this.teamsValue + " is added.", {"position": "top-center", autoClose: 2000});
        this.teamsValue = '';
        this.openModal = false;
        this.workingAdd = false;
        this.getTeams();
      } catch (e) {
        toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
        setTimeout(() => {
          this.workingAdd = false;
        }, "3000");
      }
    },

    editModalHandler(id, name, status) {
      this.teamsValue = name;
      this.teamsId = id;
      this.switchStatus = status;
      this.openModalEdit = true;
    },

    async updateTeams() {
      this.workingEdit = true;
      try {
        await updateTeamsApi(this.teamsId, this.teamsValue, this.switchStatus);
        toast.success(this.teamsValue + " is edited.", {"position": "top-center", autoClose: 2000});
        this.teamsValue = '';
        this.openModalEdit = false;
        this.workingEdit = false;
        this.getTeams();
      } catch (e) {
        toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
        setTimeout(() => {
          this.workingEdit = false;
        }, "3000");
      }
    },

    deleteModalHandler(id, name) {
      this.teamsValue = name;
      this.teamsId = id;
      this.openModalDelete = true;

    },

    async deleteTeams() {
      this.workingDelete = true;
      try {
        await deleteTeamsApi(this.teamsId);
        toast.success(this.teamsValue + " is deleted successfuly.", {"position": "top-center", autoClose: 2000});
        this.teamsValue = '';
        this.openModalDelete = false;
        this.workingDelete = false;
        this.getTeams();
      } catch (e) {
        toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
        setTimeout(() => {
          this.workingDelete = false;
        }, "3000");
      }
    },
  },
  mounted() {
    this.getTeams();
  }
};
</script>
<style lang="scss" scoped>
.modal-button-wrapper {
  gap: 16px;

  .button {
    height: 44px;
  }
}

.flag__content {
  color: var(--light-dark);
}
</style>