export default {
    namespaced: true,
    state() {
        return {
            tables: [],
            users: []
        }
    },
    mutations: {
        setTables(state, value) {
            state.tables = value;
        }
    },
    actions: {
        addTable(context, value) {
            context.commit('setTables', [
                ...context.state.tables, value
            ])
        },
        addSeatOrder(context, value) {
            context.commit('setTables', [

                ...context.state.tables.map(table => {
               
                    return {
                        ...table,
                        seats: table.seats.map(seat => {

                            if(seat.id !== value.id) {
                                return seat
                            }

                            return {
                                ...seat,
                                orders: [...value.order]
                            
                            }
                        })
                    }
                })
            ])



            // context.commit('setOrder', {
                
            // })
        }
    },
    getters: {
        getTables: (state) => (waiterId) => {
            if(waiterId === null) {
                return state.tables
            }else {
                return state.tables.filter(e => e.waiterId === waiterId)
            }
        }
    }
}