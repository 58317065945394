import {getTeamleadsApi, getAllLocationsApi, getAllSenioritiesApi, getAllSkillsApi, getAllPositionsApi, getAllContractTypesApi} from '../api/general'

export const getPaginationPayload = data => {
    const {current_page, next_page_url, prev_page_url} = data

    return {
        current_page,
        next_page_url,
        prev_page_url
    }
}

export const getTeamleads = async () => {
    try {
        const { data } = await getTeamleadsApi();
        return data
    } catch (e) {
        return []
    }
}

export const getLocations = async() => {
    try {
        const { data } = await getAllLocationsApi();
        return data
    } catch (e) {
        return []
    }
}

export const getSeniority = async() => {
    try {
        const { data } = await getAllSenioritiesApi();
        return data
    } catch (e) {
        return []
    }
}

export const getSkills = async() => {
    try {
        const { data } = await getAllSkillsApi();
        return data
    } catch (e) {
        return []
    }
}

export const getPositions = async() => {
    try {
        const { data } = await getAllPositionsApi();
        return data
    } catch (e) {
        return []
    }
}

export const getContractTypes = async() => {
    try {
        const { data } = await getAllContractTypesApi();
        return data
    } catch (e) {
        return []
    }
}
