export default {
    namespaced: true,
    state() {
        return {
            menu: {
                drinks: [
                    "Koka kola",
                    "Jagoda",
                    "Breskva",
                    "Kajsija",
                    "Turska kafa",
                    "Espreso",
                    "Rakija",
                    "Vinjak",
                    "Viski",
                    "Voda",
                ],
                food: [
                    "pljeskavica",
                    "raznjic",
                    "karadjordjeva",
                    "becka",
                    "batak",
                    "som",
                    "stuka",
                    "smudj",
                    "muckalica",
                ]
            }
        }
    },

    getters: {
        menuDrinks(state) {
            return state.menu.drinks;
        },
        menuFood(state) {
            return state.menu.food;
        }
    }
}