<template>
  <aside class="aside">
    <div class="aside__top">
      <div class="aside__header">
        <router-link :to="{name: 'employee'}">
          <div class="flag">
            <div class="flag__icon">
              <img src="@/assets/img/logo.svg" alt="" />
            </div>
            <div class="flag__content">Bridgewater Labs</div>
          </div>
        </router-link>
      </div>
      <div class="divider"></div>
      <div class="aside__nav">
        <ul>
          <li>
            <router-link
              active-class="active"
              class="aside__link"
              :to="{name: 'employee'}"
            >
              <span class="o-flex o-flex--center link-wrapper">
                <img
                  src="@/assets/img/group.svg"
                  class="mr--"
                  alt="group"
                />Employee
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              active-class="active"
              class="aside__link"
              :to="{name: 'project'}"
            >
              <span class="o-flex o-flex--center link-wrapper">
                <img
                  src="@/assets/img/page.svg"
                  class="mr--"
                  alt="page"
                />Project
              </span>
            </router-link>
          </li>

          <Accordion>
            <template v-slot:asideTitle>
              <span class="o-flex o-flex--center link-wrapper">
                <img
                  src="@/assets/img/settings.svg"
                  class="mr--"
                  alt="settings"
                />
                Entity Manager
              </span>
            </template>
            <template v-slot:asideOptions>
              <ul class="mb0 aside__sub-list">
                <li>
                  <router-link
                    active-class="active"
                    :to="{name: 'contract-types'}"
                    class="aside__sub-link"
                    >Contract Types</router-link
                  >
                </li>
                <!-- <li>
                  <router-link
                    active-class="active"
                    :to="{name: 'status-options'}"
                    class="aside__sub-link"
                    >Status Options</router-link
                  >
                </li> -->
                <li>
                  <router-link
                    active-class="active"
                    :to="{name: 'positions'}"
                    class="aside__sub-link"
                    >Positions</router-link
                  >
                </li>
                <li>
                  <router-link
                    active-class="active"
                    :to="{name: 'skills'}"
                    class="aside__sub-link"
                    >Skills</router-link
                  >
                </li>
                <li>
                  <router-link
                    active-class="active"
                    :to="{name: 'seniority-levels'}"
                    class="aside__sub-link"
                    >Seniority Levels</router-link
                  >
                </li>
                <li>
                  <router-link
                    active-class="active"
                    :to="{name: 'teams'}"
                    class="aside__sub-link"
                    >Teams</router-link
                  >
                </li>
                <li>
                  <router-link
                    active-class="active"
                    :to="{name: 'location'}"
                    class="aside__sub-link"
                    >Location</router-link
                  >
                </li>
              </ul>
            </template>
          </Accordion>
        </ul>
      </div>
    </div>
    <div class="aside__bottom">
      <ul class="mb0">
        <li>
          <Logout/>
        </li>
      </ul>
    </div>
  </aside>
</template>
<script>
import Accordion from '../Accordion/Accordion.vue';
import Logout from "../../components/Auth/Logout.vue"
export default {
  name: 'Aside',
  components: { Accordion, Logout },
};
</script>

<style lang="scss" scoped>
.aside {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  height: 100dvh;
  background-color: var(--white);
  width: var(--aside-width);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 12px;

  &__link,
  &__sub-link {
    color: var(--aside-link-color);
    font-size: 14px;
    line-height: 1.3;
    display: flex;
    border-radius: 8px;
    &:hover,
    &.active {
      background-color: var(--aside-link-hover-color);
    }
  }

  &__sub-link {
    padding: 10px 32px;
    display: flex;
  }

  .link-wrapper {
    padding: 10px 8px;
  }
}

.aside__header {
  padding: 8px;
}
</style>
