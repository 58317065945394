<template>
    <main :class="{'h-100': loadPage}">
        <section class="employee-filter">
            <div class="search-box">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    d="M17 17L21 21M11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 13.2053 18.1077 15.2022 16.6644 16.6493C15.2161 18.1015 13.213 19 11 19Z"
                    stroke="#637083"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    />
                </svg>
                <input
                    type="search"
                    class="input input--primary input--search"
                    placeholder="Search employees by name"
                />
            </div>
    
            <div class="filter-wrapper">
                <VueMultiselect v-model="skillId" placeholder="Select one" :options="skills" label="name" track-by="name"/>
                <VueMultiselect v-model="seniorityId" placeholder="Select one" :options="skills" label="name" track-by="name"/>
                <VueMultiselect v-model="roleId" placeholder="Select one" :options="skills" label="name" track-by="name"/>
                <VueMultiselect v-model="statusId" placeholder="Select one" :options="skills" label="name" track-by="name"/>
            </div>
    
            <router-link
                :to="{name: 'addEmployee'}"
                class="button button--primary">
                <svg fill="none" width="20" viewBox="0 0 20 20">
                    <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5 10h5m5 0h-5m0 0V5m0 5v5"/>
                </svg>
                Add new
            </router-link>
    
        </section>
    
        <div class="card">
            <div class="card__body" :class="{'h-100': loadPage}">
                <Loader v-if="loadPage"/>
    
                <div v-else class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr v-for="item in data" :key="item.id" :class="{'inactive': item.status !== 'active'}">
                                <td width="30%">
                                    <div class="flag">
                                        <div class="flag-img" >
                                        </div>
                                        <div class="flag-content">
                                            <div class="seniority-box" :class="[`seniority-box-${color(item?.seniority_level ? item?.seniority_level?.name : '')}`]">
                                                {{ item?.seniority_level ? item.seniority_level.name : 'No Seniority' }}
                                            </div>
    
                                            <router-link :to="{ name: 'view-edit', params: { id: item.id } }" class="mb-">{{item?.first_name}} {{item?.last_name}}</router-link>
                                            <div class="o-flex">
                                                <p class="mb0">Positions:</p>   
                                                <ul class="mb0 position-list">
                                                    <li v-for="(position, index) in item.positions" :key="position?.id">{{ position?.name || 'No Position' }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="skills-wrapper">
                                        <p class="mb0">Skills:</p>
                                        <ul class="mb0 skill-list">
                                            <li v-for="skill in item.skills" :key="skill.id" :class="{'inactive': skill.status !== 'active'}">
                                                {{ skill?.name || 'No Name' }}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card__footer" v-if="!loadPage">
                <Pagination @page-click="getEmployee" :info="pagination" />
            </div>
        </div>
    </main>
</template>
<script>
import VueMultiselect from 'vue-multiselect'
import Pagination from "../../components/Pagination/Pagination.vue";
import { getPaginationPayload } from "../../service/general";
import { getResourcesApi } from '../../api/employee/index';
import { toast } from 'vue3-toastify';
import Loader from '../../components/Loader/Loader.vue';


export default {
    name:"EmployeeDashboard",
    components: {
        VueMultiselect,
        Pagination,
        Loader,
    },
    data() {
        return {
            loadPage: true,
            pagination: null,
            skillId: null,
            seniorityId: null,
            roleId: null,
            statusId: null,
            skills: [
                {
                    "id": 13,
                    "parent_id": 11,
                    "name": "Acccount Management",
                    "status": "active",
                    "is_pm": 1,
                    "created_at": "2024-09-18T11:26:15.000000Z",
                    "updated_at": "2024-09-18T11:26:15.000000Z"
                },
                {
                    "id": 4,
                    "parent_id": 1,
                    "name": "Angular",
                    "status": "active",
                    "is_pm": 0,
                    "created_at": "2024-09-18T11:26:15.000000Z",
                    "updated_at": "2024-09-18T11:26:15.000000Z"
                },
                {
                    "id": 5,
                    "parent_id": null,
                    "name": "Backendd",
                    "status": "active",
                    "is_pm": 0,
                    "created_at": "2024-09-18T11:26:15.000000Z",
                    "updated_at": "2024-09-18T11:43:06.000000Z"
                },
                {
                    "id": 10,
                    "parent_id": 5,
                    "name": "C#d",
                    "status": "active",
                    "is_pm": 0,
                    "created_at": "2024-09-18T11:26:15.000000Z",
                    "updated_at": "2024-09-18T11:43:11.000000Z"
                },
            ],
            data:[],
        }
    },
    methods: {
        color(seniority) {
            if (seniority === 'Senior') {
                return 'senior';
            } else if (seniority === 'Medior') {
                return 'medior';
            } else if (seniority === 'Junior') {
                return 'junior';
            } else {
                return '';
            }
        },
        async getEmployee(page = 1) {
            try {
                const { data } = await getResourcesApi({page:page});
                this.data = data.data
                this.pagination = getPaginationPayload(data)
                this.loadPage = false;
            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
            }

            // await getResources({page:page}).then((data) => {
            //     if(!data) return
            //     console.log(data)
            //     this.data = data.data.data;
            //     this.pagination = getPaginationPayload(this.data)
            //     this.loadPage = false;

            // }).catch((error) => {
            //     console.log('odje')
            //     console.log(error)
            //     console.log('odje2')
            //     toast.error(error.response?.data.message);
                

            //     this.loadPage = false;
            // });

            
        },
    },
    mounted() {
        this.getEmployee();
    }
}
</script>
<style lang="scss" scoped>
    .table {
        td {
            vertical-align: top;
            padding-right: 32px;

            &:last-child {
                padding-left: 32px;
                table-layout: fixed;
                border-left: 1px solid #e4e7ec;
            }
        }

        tr {
            &.inactive {
                opacity: .4;
            }
        }
    }
    .employee-filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
    }

    .search-box {
        max-width: 320px;
    }

    .filter-select {
        min-width: 290px;
    }

    .button--primary {
        white-space: nowrap;
        width: auto;
        gap: 8px;
    }

    .flag {
        align-items: flex-start;
    }

    .flag-img {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-color: #fafafa;

    }

    .flag-content {
        padding-top: 2px;
        flex: 1;
        position: relative;

        a {
            font-weight: 600;
            color: #0166FF;
            display: flex;
            margin-bottom: 10px;
        }

        p {
            margin-right: 10px;
            font-size: 12px;
            color: #344051;
        }
    }

    .seniority-box {
        position: absolute;
        right: 0;
        top: 2px;
        height: 20px;
        padding: 0 10px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 4px;
        display: flex;
        align-items: center;

        &-senior {
            background-color: #FFE5E5;
            color: #790C0C;
        }

        &-medior {
            background-color: #E5F2FF;
            color: #00254D;
        }

        &-junior {
            background-color: #F0F9FF;
            color: #0B4A6F;
        }
    }

    .skills-wrapper {
        display: flex;
        gap: 10px;
        position: relative;
        height: 100%;
        align-items: flex-start;

        li {
            &.inactive {
                opacity: .5;
            }
        }
        
        p {
            font-size: 12px;
            padding-top: 2px;
            color: #344051;
        }
    }

    .position-list {
        li {
            &:not(:last-child) {
                &:after {
                    margin-right: 5px;
                    content:", ";
                }
            }
        }
    }

    .position-list, .skill-list {
        display: flex;
        flex-wrap: wrap;

        li {
            font-size: 12px;
            font-weight: 600;
        }
    }

    .skill-list {
        gap: 8px;

        li {
            height: 20px;
            padding: 0 10px;
            border: 1px solid #8FC4FF;
            border-radius: 4px;
            display: flex;
            align-items: center;
        }
    }

</style>