import {createStore} from 'vuex';
import tablesModule from './modules/tables.js';
import menuModule from './modules/menu.js';
import userModule from './modules/user.js';


const store = createStore({
    modules: {
        menu: menuModule,
        tables: tablesModule,
        user: userModule,
    }
  })

  export default store;