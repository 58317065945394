import { createRouter, createWebHistory } from 'vue-router';
import AuthLayout from '../../src/layout/AuthLayout.vue';
import DashboardLayout from '../../src/layout/DashboardLayout.vue';
import LoginPage from '../view/auth/LoginPage.vue';
import EmployeePage from '../view/EmployeePage.vue';
import EmployeeDashboard from '../view/employee/EmployeeDashboard.vue';
import EmployeeAdd from '../view/employee/EmployeeAdd.vue';
import ProjectPage from '../view/ProjectPage.vue';
import ProjectDashboard from '../view/project/ProjectDashboard.vue';
import ProjectAdd from '../view/project/ProjectAdd.vue';
import ContractType from '../view/ContractTypesPage.vue';
import StatusOptions from '../view/StatusOptionsPage.vue';
import Positions from '../view/PositionsPage.vue';
import Skills from '../view/SkillsPage.vue';
import SeniorityLevels from '../view/SeniorityLevelsPage.vue';
import Teams from '../view/TeamsPage.vue';
import Location from '../view/LocationPage.vue';
import EmployeeViewEdit from '@/view/employee/EmployeeViewEdit.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginPage,
      meta: {
        layout: AuthLayout,
      },
    },
    {
      path: '/project',
      name: 'project',
      component: ProjectPage,
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/contract-types',
      component: ContractType,
      name: 'contract-types',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/status-options',
      component: StatusOptions,
      name: 'status-options',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/positions',
      component: Positions,
      name: 'positions',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/skills',
      component: Skills,
      name: 'skills',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/seniority-levels',
      component: SeniorityLevels,
      name: 'seniority-levels',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/teams',
      component: Teams,
      name: 'teams',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/location',
      component: Location,
      name: 'location',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/employee',
      component: EmployeePage,
      meta: {
        layout: DashboardLayout,
        breadcrumb: 'Employee',
      },
      children: [
        {
          path: '',
          name: 'employee',
          component: EmployeeDashboard,
          meta: {
            layout: DashboardLayout,
            // breadcrumb: 'Employee',

          },
        },
        {
          path: 'add-employee',
          component: EmployeeAdd,
          name: 'addEmployee',
          meta: {
            layout: DashboardLayout,
            breadcrumb: 'Add Employee ',
          },
        },
        {
          path: 'view-edit/:id',
          component: EmployeeViewEdit,
          name: 'view-edit',
          meta: {
            layout: DashboardLayout,
            breadcrumb: 'View & Edit ',
          },
        },
      ]
    },
    {
      path: '/project',
      component: ProjectPage,
      meta: {
        layout: DashboardLayout,
        breadcrumb: 'Project',
      },
      children: [
        {
          path: '',
          name: 'project',
          component: ProjectDashboard,
          meta: {
            layout: DashboardLayout,
            // breadcrumb: 'Employee',

          },
        },
        {
          path: 'add-project',
          component: ProjectAdd,
          name: 'add-project',
          meta: {
            layout: DashboardLayout,
            breadcrumb: 'Add Project ',
          },
        },
      ]
    },
  ],
});

export default router;
