<template>
    <main :class="{'h-100': loadPage}">
        <Header></Header>
        <EmployeeForm v-if="data" :payload="data" @loading="loading()" :working="working" @formData="submitForm"/>
    </main>
</template>
<script>
import Header from '@/components/Header/Header.vue';
import EmployeeForm from './EmployeeForm.vue';
import { toast } from 'vue3-toastify';
import { getEmployeeDetailsApi, updateEmployeeApi } from '../../api/employee'

export default {
    name:"EmployeeViewEdit",
    components: {
        Header,
        EmployeeForm,
    }, 

    data() {
        return {
            userId: null, 
            loadPage: true,
            data: null,
            working: false,
        };
    },

    methods: {
        loading(status) {
            this.loadPage = status 
        },
        async getEmployeeDetails() {
            try {
                const { data } = await getEmployeeDetailsApi(this.userId);
                this.data = data
            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
            }
        },
        async submitForm(payload) {
            console.log(payload)
            this.working = true
            try {
                await updateEmployeeApi(this.userId,payload)
                this.working = false
                await this.$router.push({name: 'employee'})
                toast.success('Your employee is successfully edited', {"position": "top-center", autoClose: 2000});

            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                setTimeout(() => {
                    this.working = false;
                }, "3000");
            }
        }
    },

    created() {
        this.userId = this.$route.params.id;
        this.getEmployeeDetails();
    },
}
</script>
<style lang="scss" scoped>
</style>