<template>
    <div class="toggle-switch">
      <input
        type="checkbox"
        :id="id"
        :checked="isActive"
        @change="toggleStatus"
      />
      <label class="switch-wrapper" :for="id">
        <div class="switch-box">
          <span></span>
        </div>
        <span class="text">{{ isActive ? 'Active' : 'Inactive' }}</span>
      </label>
    </div>
</template>
  
<script>
    export default {
        name: "ToggleSwitch",
        props: {
            id: {
                type: String,
                default: "1",
            },
            status: {
                type: String,
                default: "inactive",
                validator: value => ['active', 'inactive'].includes(value),
            }
        },
        emits: ['status'],
        computed: {
            isActive() {
                return this.status === 'active';
            }
        },
        methods: {
            toggleStatus(event) {
                const newStatus = event.target.checked ? 'active' : 'inactive';
                this.$emit('status', newStatus);
                console.log(newStatus)
            }
        }
    };
</script>
<style lang="scss" scoped>

    .toggle-switch {
        input {
            position: absolute;
            z-index: -1;
            visibility: hidden;
            opacity: 0;
        }
        input:checked + .switch-wrapper .switch-box span {
            transform: translate(20px, 0);
        }
    }

    .switch-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }

    .switch-box {
        width: 44px;
        height: 24px;
        border-radius: 12px;
        background-color: #414E62;
        position: relative;

        span {
            position: absolute;
            left: 2px;
            top: 2px;
            border-radius: 50%;
            background-color: #fff;
            width: 20px;
            height: 20px;
            transition: all .3s ease;
        }
    }
</style>