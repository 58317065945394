<template>
    <main :class="{'h-100': loadPage}">
        <Header></Header>
        <EmployeeForm @loading="loading()" :working="working" @formData="submitForm"/>
    </main>
</template>
<script>
import Header from '@/components/Header/Header.vue';
import EmployeeForm from './EmployeeForm.vue';
import { toast } from 'vue3-toastify';
import { addEmployeeApi } from '../../api/employee/index';

export default {
    name: "EmployeeAdd",
    components: {
        Header,
        EmployeeForm,
    },
    data() {
        return {
            loadPage: true,
            working: false,
        }
    },
    methods: {
        loading(status) {
            this.loadPage = status
        },
        async submitForm(payload) {
            this.working = true
            try {
                await addEmployeeApi(payload)
                this.working = false
                await this.$router.push({name: 'employee'})
                toast.success('Your employee is successfully created', {"position": "top-center", autoClose: 2000});

            } catch (e) {
                console.log(e)
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                setTimeout(() => {
                    this.working = false;
                }, "3000");
            }
        }
    }
}
</script>
<style lang="scss" scoped>
</style>