<template>
    <Form :validation-schema="schema">
        <div class="card">
            <div class="card__header card--border o-flex o-flex--center o-flex--justify">
                <div class="flag">
                    <div class="flag__icon">
                        <img src="@/assets/img/add-project.svg" alt="add-project" />
                    </div> 
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Workforce</h2>
                        <p class="mb0">Manage your project here</p>
                    </div>
                </div>
            </div>
            <div class="card__body">
                <div class="row">
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Project name <span>*</span></p>
                            <div class="relative">
                                <Field type="text" name="projectName" class="input input--primary" :placeholder="'Enter'" v-model="projectName"/>
                                <ErrorMessage class="text-error" name="projectName"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Client name <span>*</span></p>
                            <div class="relative">
                                <Field type="text" name="clientName" class="input input--primary" :placeholder="'Enter'" v-model="clientName"/>
                                <ErrorMessage class="text-error" name="clientName"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Start Date <span>*</span></p>
                            <div class="relative">
                                <Field name="startDate" v-slot="{ field }">
                                    <vue-date-picker 
                                        v-bind="field" 
                                        :model-type="'yyyy-MM-dd'" 
                                        :format="'dd.MM.yyyy'" 
                                        placeholder="Select Date"
                                        v-model="startDate"
                                        :ui="{ input: 'date-picker' }"
                                    />
                                </Field>
                                <ErrorMessage class="text-error" name="startDate"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>End Date</p>
                            <div class="relative">
                                <vue-date-picker 
                                required 
                                :model-type="'yyyy.MM.dd'"
                                :format="'dd.MM.yyyy'"
                                placeholder="Select Date"
                                v-model="endDate"
                                name="endDate"
                                :ui="{ input: 'date-picker' }"
                                />
                                <ErrorMessage class="text-error" name="endDate"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Team lead</p>
                            <div class="relative">
                                <VueMultiselect name="teamLead" v-model="teamLeadId" placeholder="Select one" :options="allTeamLeads" label="first_name" track-by="first_name"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Priority</p>
                            <div class="relative">
                                <VueMultiselect name="teamLead" v-model="teamLeadId" placeholder="Select one" :options="allTeamLeads" label="first_name" track-by="first_name"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Status</p>
                            <div class="relative">
                                <VueMultiselect name="teamLead" v-model="teamLeadId" placeholder="Select one" :options="allTeamLeads" label="first_name" track-by="first_name"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Project Color</p>
                            <color-picker format="'hex'" class="input input--primary" v-model:pureColor="pureColor"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card__footer card--border o-flex o-flex--right">
                <router-link :to="{name: 'employee'}" class="button button--secondary">Cancel</router-link>
                <button type="button" @click="submit" class="button button--primary" :disabled="working">Save Changes</button>
            </div>
        </div>
    </Form>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueMultiselect from 'vue-multiselect';
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { toast } from 'vue3-toastify';
import { getTeamleads } from '../../service/general';
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

export default {
    name: "ProjectForm",
    components: {
        Field,
        Form,
        ErrorMessage,
        VueDatePicker,
        VueMultiselect,
        ColorPicker ,
    },
    data() {
        return {
            working: false,
            projectName: '',
            clientName: '',
            startDate: null,
            endDate: null,
            schema: yup.object({
                projectName: yup
                .string()
                .required('Project name is required')
                .min(2, 'Must be at least 2 characters'),
                clientName: yup
                .string()
                .required('Client name is required')
                .min(2, 'Must be at least 2 characters'),
                startDate: yup
                .date()
                .nullable()
                .required('Start date is required'),
            }),
            teamLeadId: "", 
            allTeamLeads: [],
            pureColor: "#000000",
        }
    },

    methods: {
        async submit() {
            this.working = true;
            // const payload = {

            // }

            try {
                // await addEmployeeApi(payload)
                this.working = false
                // await this.$router.push({name: 'employee'})

            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                setTimeout(() => {
                    this.working = false;
                }, "3000");
            }
        }
    },
    async created() {
        this.allTeamLeads = await getTeamleads();
    },
}
</script>
<style lang="scss" scoped>
    .card__header {
        padding-bottom: 24px; 
        padding-top: 9px;
    }

    .card__body {
        padding: 24px 0;
    }

    .card__footer {
        padding-top: 24px;
        padding-bottom: 9px;
        gap: 10px;
    }
</style>